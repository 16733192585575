import { Button, Card } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { BiTrash } from 'react-icons/bi'
import { getWallets } from '../../utils/walletUtils'
import { HttpService } from '../../utils/axios-httpService'

const LoadWallet = () => {
  const [wallets, setWallets] = useState([])
  const [loading, setLoading] = useState(false)
  const guildId = localStorage.getItem('guildId')

  const fetchWallets = () => {
    getWallets(guildId).then((data) => {
      setWallets(data)
    })
  }

  useEffect(() => {
    if (!wallets || wallets.length === 0) {
      fetchWallets()
    }
  }, [])

  const deleteWallet = async (wallet) => {
    HttpService.deleteWalletById(guildId, wallet.info)
      .then(() => {
        toast.success('Wallet deleted successfully!')
        fetchWallets()
      })
      .catch((error) => {
        toast.error('Error deleting wallet!')
      })
  }

  return (
    <>
      {wallets.length > 0 && (
        <section className="bg-basic p-5 rounded-lg flex flex-col gap-y-5">
          <span className="text-xl font-semibold pl-2">My wallets</span>
          {wallets.length > 0 &&
            wallets.map((wallet, index) => (
              <Card
                className="flex flex-row p-5 justify-between items-center bg-second"
                key={index}
              >
                <div>{wallet.walletName}</div>
                <div className="flex items-center gap-x-1">
                  <Button
                    disabled={loading}
                    onClick={() => deleteWallet(wallet)}
                    className="flex items-center justify-center"
                    size="sm"
                    variant="text"
                    color="red"
                  >
                    <BiTrash size={20} />
                  </Button>
                </div>
              </Card>
            ))}
        </section>
      )}
    </>
  )
}

export default LoadWallet
