import React, { useState } from 'react'
import { InputDefault, InputNumber } from '../components/ui/input'
import Switch from '../components/ui/switch'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css'
import 'react-multi-date-picker/styles/colors/purple.css'
import { Button } from '@material-tailwind/react'

const CreatePasswordLock = () => {
  const [password, setPassword] = useState('')
  const [limitPerUses, setLimitPerUses] = useState(false)
  const [expirationDate, setExpirationDate] = useState(false)
  const [limitPerUsesValue, setLimitPerUsesValue] = useState(1)
  const [selectedDuration, setSelectedDuration] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const handleCheckboxChange = (value) => {
    setSelectedDuration(value);

    if (value !== 'Custom') {
      const dates = calculateEndDate(value);
      setStartDate(dates.start);
      setEndDate(dates.end);
    }
  };

  const formatDateToUS = (date) => {
    return date?.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  }


  return (
    <>
      <section className='flex flex-col w-full gap-y-5'>
        <div className='flex gap-3'>
          <InputDefault
            label='Password Password*'
            placeholder='Enter your password here'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

        </div>
        <div className='flex gap-x-10 w-full'>
          <div className='flex gap-5 w-full items-end'>
            <Switch
              handleCheckboxChange={() => setExpirationDate(!expirationDate)}
              isChecked={expirationDate}
              span='Define expiration date'
              labelLeft='No'
              labelRight='Yes'
            />
            {expirationDate && (
              <div className='flex flex-col gap-2 w-52' >
                <DatePicker
                  className="rmdp-prime bg-dark purple"
                  value={endDate}
                  onChange={setEndDate}
                  minDate={startDate}
                  format="MM/DD/YYYY, HH:mm"
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    border: '1px solid #4e537b ',
                    height: '53px',
                    backgroundColor: '#2c2f48',
                    textAlign: 'center',
                  }}
                  containerStyle={{
                    width: '100%',
                    backgroundColor: '#2c2f48',
                  }}
                  plugins={[<TimePicker hideSeconds />]}
                />
              </div>
            )}
          </div>
          <div className='flex gap-3 items-end w-full h-[76px]'>
            <Switch
              handleCheckboxChange={() => setLimitPerUses(!limitPerUses)}
              isChecked={limitPerUses}
              span='Define limit per uses'
              labelLeft='No'
              labelRight='Yes'
            />
            {limitPerUses && (
              <InputNumber
                placeholder='Enter your limit per uses here'
              />
            )}
          </div>
        </div> 
        <div className='flex justify-end'>
          <Button>
            Creat e
          </Button>
        </div>
      </section>
    </>
  )
}

export default CreatePasswordLock