import { Button, Card, Input } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BiSearch } from 'react-icons/bi'
import { CgTrash } from 'react-icons/cg'
import { fetchRoles } from '../../../utils/rolesUtils'
import { InputNumber } from '../../components/ui/input'
import { SelectDefault } from '../../components/ui/select'

export const RolePerQty = ({ RoleQuantity, listRoleQuantity }) => {
  const guildId = localStorage.getItem('guildId')
  const [selectedRole, setSelectedRole] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [roles, setRoles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rolesPerQty, setRolesPerQty] = useState(
    RoleQuantity
      ? RoleQuantity.map(({ RoleName, NumberOfNfts, RoleId }) => ({
          RoleName,
          NumberOfNfts,
          RoleId,
        }))
      : []
  )

  const handleValueQuantity = (value) => {
    setQuantity(value)
  }

  const handleAddRoleQty = () => {
    const errors = []
    if (!selectedRole) {
      errors.push('Select a role')
    }
    if (quantity <= 0) {
      errors.push('Quantity must be greater than 0')
    }
    if (errors.length > 0) {
      toast.error(errors.join(' '))
      return
    }

    const existingRoleIndex = rolesPerQty.findIndex(
      (role) => role.RoleId === selectedRole.value
    )
    if (existingRoleIndex !== -1) {
      toast.error('Opss, role already added with a different quantity')
      return
    }

    const newRoleQty = {
      RoleName: selectedRole.label,
      NumberOfNfts: quantity,
      RoleId: selectedRole.value,
    }
    setRolesPerQty([...rolesPerQty, newRoleQty])
    setSelectedRole(null)
    setQuantity(0)
  }

  const handleDeleteRoleQty = (index) => {
    const updatedRolesPerQty = rolesPerQty.filter((_, i) => i !== index)
    setRolesPerQty(updatedRolesPerQty)
  }

  // load roles
  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))


  const filteredRolesPerQty = rolesPerQty?.filter((roleQty) =>
    roleQty.RoleName.toLowerCase().includes(searchQuery.toLowerCase())
  )

  useEffect(() => {
    listRoleQuantity(rolesPerQty)
  }, [rolesPerQty, listRoleQuantity])

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Add Role Quantity */}
        <div className="flex gap-3 w-full">
          {/* QUANTITY REWARD */}
          <InputNumber
            minimum={1}
            label="Quantity"
            value={quantity}
            onChange={handleValueQuantity}
          />
          {/* ROLE */}
          <SelectDefault
            options={dropdownRoles}
            onChange={(selectedOption) => {
              setSelectedRole(selectedOption)
            }}
            placeholder="Select Role"
            label="Role"
            value={selectedRole}
            isLoading={isLoading}
          />
          {/* ADD BUTTON */}
          <div className="flex items-end justify-center shadow-lg">
            <Button
              color="deep-purple"
              className="bg-main hover:bg-mainHover w-12 h-full max-h-[52px] flex items-center justify-center"
              onClick={handleAddRoleQty}
            >
              ADD
            </Button>
          </div>
        </div>
        {/* Grid of Role Quantity */}
        <Card className="bg-basic min-h-28 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-lg">
          <Input
            placeholder="Filter by rule"
            variant="static"
            color="deep-purple"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            className="w-full bg-second border-none px-5 text-white"
            icon={<BiSearch className="text-xl text-main -ml-10" />}
          />
          <table className="w-full text-left">
            <thead className="text-xs bg-second uppercase">
              <tr>
                <th scope="col" className="hidden text-center py-2">
                  ID
                </th>
                <th scope="col" className="text-start py-2 pl-5 w-44">
                  Quantity
                </th>
                <th scope="col" className="text-start py-2 pl-5">
                  Role
                </th>
                <th scope="col" className="text-start py-2 pl-5"></th>
              </tr>
            </thead>
            {filteredRolesPerQty?.map((roleQty, index) => (
              <tbody
                key={index}
                className={!filteredRolesPerQty ? 'hidden' : ''}
              >
                <tr className="bg-basic hover:bg-third border-second border-t font-light">
                  <td className="px-5 py-2 border-r border-second">
                    {roleQty.NumberOfNfts}
                  </td>
                  <td className="px-5 py-2 border-r border-second">
                    {roleQty.RoleName}
                  </td>

                  <td className=" border-second text-center h-8 w-10">
                    <Button
                      color="deep-purple"
                      className="bg-main hover:bg-mainHover flex items-center justify-center text-white h-full w-10 p-px rounded-none rounded-l-lg"
                      onClick={() => handleDeleteRoleQty(index)}
                    >
                      <CgTrash size={20} />
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
          {!filteredRolesPerQty.length > 0 && (
            <div className="text-center italic text-xs p-2">
              no rule has been configured
            </div>
          )}
        </Card>
      </div>
    </>
  )
}
