import React from 'react'
import DecentralizedGallery from './_components/DecentralizedGallery'
import DecentralizedUpload from './_components/DecentralizedUpload'
//TODO : Aplicar logica após corrigir erro 500 


const DecentralizedStorage = () => {
  return (
    <>
      <div className="flex flex-col container mx-auto w-full h-full py-5 text-white gap-y-5">
        <DecentralizedUpload />
        <DecentralizedGallery />
      </div>
    </>
  )
}

export default DecentralizedStorage
