import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FullyProtectedRoute from './utils/FullyProtectedRoute '
import { isAuthenticated } from './utils/authUtils'
import './App.css'
import AntiBotMembers from './admin/AntiBotMembers/AntiBotMembers'
import ChooseMain from './admin/ChooseServer/Main'
import DecentralizedStorage from './admin/DecentralizedStorage/DecentralizedStorage'
import Giveaway from './admin/Giveaway/Giveaway'
import EditHolderVerification from './admin/HolderVerification/EditHolderVerification'
import HolderVerification from './admin/HolderVerification/HolderVerification'
import AdminMain from './admin/Main'
import NotFound from './admin/NotFound/NotFound'
import RevenueSharing from './admin/RevenueSharing/RevenueSharing'
import EditStakingSetup from './admin/StakingSetup/EditStakingSetup'
import StakingSetup from './admin/StakingSetup/StakingSetup'
import TicketSystem from './admin/TicketSystem/TicketSystem'
import TokenCreation from './admin/TokenCreation/TokenCreation'
import Premium from './admin/User/Premium'
import VotingSystem from './admin/VotingSystem/VotingSystem'
import WalletManagement from './admin/WalletManagement/WalletManagement'
import ErrorPage from './components/Error/Error'
import MoreFeature from './components/Feature/MoreFeatures'
import Main from './components/Main/Main'
import PasswordLock from './admin/PasswordLock/PasswordLock'
import { setupAxiosInterceptors } from './utils/axios-httpService'

const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/" />
}

function App() {
  setupAxiosInterceptors()

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/allfeatures" element={<MoreFeature />} />
        <Route
          path="/select-server"
          element={<ProtectedRoute element={<ChooseMain />} />}
        />
        <Route
          path="/dashboard"
          element={<FullyProtectedRoute element={<AdminMain />} />}
        >
          <Route
            path="antiBotMembers"
            element={<FullyProtectedRoute element={<AntiBotMembers />} />}
          />
          <Route
            path="holderverification"
            element={<FullyProtectedRoute element={<HolderVerification />} />}
          />
          <Route
            path="holderverification/:id"
            element={
              <FullyProtectedRoute element={<EditHolderVerification />} />
            }
          />
          <Route
            path="stakingsetup"
            element={<FullyProtectedRoute element={<StakingSetup />} />}
          />
          <Route
            path="stakingsetup/:id"
            element={<FullyProtectedRoute element={<EditStakingSetup />} />}
          />
          <Route
            path="tokencreation"
            element={<FullyProtectedRoute element={<TokenCreation />} />}
          />
          <Route
            path="revenuesharing"
            element={<FullyProtectedRoute element={<RevenueSharing />} />}
          />
          <Route
            path="decentralizestorage"
            element={<FullyProtectedRoute element={<DecentralizedStorage />} />}
          />
          <Route
            path="votingsystem"
            element={<FullyProtectedRoute element={<VotingSystem />} />}
          />
          <Route
            path="walletmanagement"
            element={<FullyProtectedRoute element={<WalletManagement />} />}
          />
          <Route
            path="ticket"
            element={<FullyProtectedRoute element={<TicketSystem />} />}
          />
          <Route
            path="passwordlock"
            element={<FullyProtectedRoute element={<PasswordLock />} />}
          />
          <Route
            path="giveaway"
            element={<FullyProtectedRoute element={<Giveaway />} />}
          />
          <Route
            path="premium"
            element={<FullyProtectedRoute element={<Premium />} />}
          />
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/dashboard/not-found" />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  )
}

export default App
