import {
  Button,
  Card,
  CardBody,
  CardHeader,
} from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import Tektolls from '../../img/TektoolsFace.png'
import { fetchPolls } from '../../utils/pollUtils'

const LoadVotingSystem = () => {
  const [pollList, setPollList] = useState([])
  const guildId = localStorage.getItem('selectedServer')

  useEffect(() => {
    if (pollList.length === 0) {
      fetchPolls(guildId).then((data) => {
        setPollList(data)
      })
    }
  }, [])


  return (
    <>
      <div className="flex flex-wrap-reverse items-center justify-center gap-5">
        {pollList.map((poll, index) => (
          <div className="w-80 h-[300px] rounded-lg" key={index}>
            <Card className="w-full h-full bg-third rounded-lg">
              <div className="absolute h-10 w-full bg-second top-0 rounded-t-lg"></div>
              <div className="absolute h-4 w-full bg-second bottom-0 rounded-b-lg"></div>
              <div className="absolute h-full w-4 bg-second right-0 rounded-l-lg"></div>
              <div className="absolute h-full w-4 bg-second left-0 rounded-r-lg"></div>

              <CardBody className="h-full  flex flex-col">
                {/* Headers */}
                <CardHeader className="relative flex items-center gap-x-2 bg-second p-2 rounded-b-xl rounded-t-none shadow-lg mb-2">
                  <img src={Tektolls} className="w-10 h-10 rounded-full" />
                  <span>Progress... </span>
                </CardHeader>
                {/* Poll Question */}
                <div className="max-h-16 h-16 bg-basic rounded-md hover:bg-none mb-3 overflow-hidden hover:cursor-pointer group shadow-xl shadow-dark/40 transition-all duration-500">
                  <div className="w-full h-full flex flex-col">
                    <span className="bg-main px-2 py-1 rounded-lg h-fit w-fit text-xs text-center group-hover:hidden transition-all duration-500 m-1">
                      Poll question
                    </span>
                    <span className="w-full h-full pl-3 transition-all duration-500 py-1 bg-second">
                      {poll.PollQuestion}
                    </span>
                  </div>
                </div>

                {/* Participants */}
                <div className="flex gap-x-2 mt-3 ">
                  <div className="flex flex-col items-center rounded-lg w-full shadow-xl shadow-dark/40">
                    <span className="text-xs bg-basic w-full px-3 py-2 rounded-t-lg text-center">
                      Participants
                    </span>
                    <span className="bg-second w-full p-1 rounded-b-lg items-center flex justify-center h-full">
                      {poll.MembersVoted.length}
                    </span>
                  </div>
                  <div className="flex flex-col items-center rounded-lg w-full shadow-xl shadow-dark/40">
                    <span className="text-xs bg-basic w-full py-2 rounded-t-lg text-center uppercase">
                      Poll winner
                    </span>
                    <div className="bg-second w-full p-1 rounded-b-lg items-center flex justify-center">
                      <span className="bg-third px-2 py-0.5 rounded-lg h-full w-fit">
                        {poll.PollAnswers.length > 0
                          ? poll.PollAnswers.reduce(
                              (max, answer) =>
                                answer.Count > max.Count ? answer : max,
                              poll.PollAnswers[0]
                            ).Count > 0
                            ? poll.PollAnswers.reduce(
                                (max, answer) =>
                                  answer.Count > max.Count ? answer : max,
                                poll.PollAnswers[0]
                              ).Option
                            : 'No winner'
                          : 'No winner'}
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
              {/* Footer */}
              <div className="flex absolute -bottom-2 -right-2 justify-end p-2 w-full ">
                <div className="bg-second w-[128px] rounded-l-xl flex justify-end pr-4">
                  <Button
                    color="purple"
                    onClick={() => handleRepeat(poll)}
                    className="bg-main px-2 m-2 w-24"
                  >
                    Repeat
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </>
  )
}

export default LoadVotingSystem
