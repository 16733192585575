import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../components/ui/button'
import SectionTokenCreation from './SectionTokenCreation'
import SwitchTokenContact from './_components/SwitchTokenContact'
import { HttpService } from '../../utils/axios-httpService'
const TokenCreation = () => {
  const [isCheckedContact, setIsCheckedContact] = useState(false)
  const [tokenInfo, setTokenInfo] = useState(null)
  const handleTokenInfoChange = (info) => {
    setTokenInfo(info)
  }

  const SaveToken = async () => {
    if (!tokenInfo || Object.values(tokenInfo).some((value) => !value)) {
      toast.error('Please fill in all fields before saving the token.')
      return
    }
    try {
      const response = await HttpService.postCreateToken(tokenInfo)
      toast.success('Token created successfully!')
      setTokenInfo(null)
    } catch (error) {
      toast.error(`Error creating token: ${error.message}`)
    }
  }

  const handleClickCancel = () => {
    toast.info('Ops')
    window.location.reload()
  }

  return (
    <section className="container mx-auto h-full text-white py-5">
      <h1 className="text-4xl pb-14 font-bold ">SPL - Create Token</h1>
      <div className="flex flex-col gap-y-5 items-center">
        <SectionTokenCreation onTokenInfoChange={handleTokenInfoChange} />
        {/* Seção de Contato */}
        <div className="py-5 hidden">
          <SwitchTokenContact
            isChecked={isCheckedContact}
            setIsChecked={setIsCheckedContact}
          />
        </div>
        <div className="flex flex-row py-3 ">
          {isCheckedContact && <InputTokenContactsDiscord />}
          {isCheckedContact && <InputTokenContactsDiscord />}
          {isCheckedContact && <InputTokenContactsDiscord />}
        </div>
      </div>
      <hr className="h-px mb-5 bg-gray border-0" />
      <div className="flex justify-between pb-10 ">
        <Button
          onClick={handleClickCancel}
          type="reset"
          className="w-40 bg-basic border uppercase  rounded-lg items-center justify-center h-12 text-center text-base font-bold hover:bg-accentHouver tracking-wide transition duration-300"
        >
          Cancelar
        </Button>
        <Button
          className="uppercase bg-accentMain rounded-lg items-center justify-center w-36 h-12 text-center text-base font-bold hover:bg-accentHouver tracking-wide transition duration-300"
          onClick={SaveToken}
          type="button"
        >
          Save
        </Button>
      </div>
    </section>
  )
}

export default TokenCreation
