import { HttpService } from "./axios-httpService"

export const fetchPolls = async (guildId) => {
  try {
    const response = await HttpService.getPollsByGuildId(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error fetching polls')
  }
}
