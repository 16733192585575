import { Button, Card, CardBody, CardHeader } from '@material-tailwind/react'
import React, { useState } from 'react'
import { TiTick } from 'react-icons/ti'
import TekrosSpeaker from '../../img/TekrosSpeaker.png'

const Premium = () => {
  const [activeButton, setActiveButton] = useState(1)

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId)
  }

  return (
    <>
      <style>
        {`@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");`}
      </style>
      <div className="container mx-auto w-[500px] h-[450px] bg-bgAdmin bg-left-top bg-rotate-90 flex flex-row gap-x-5 py-5 relative text-white rounded-xl">
        <div className="w-[350px] h-full flex flex-col justify-center">
          <div className="text-base font-bold mb-10 bg-second px-3 py-5 rounded-lg -ml-10">
            <h2 className="">You've found a premium feature!</h2>
            <h3 className="">Upgrade to enjoy its full potential.</h3>
            <div className="flex flex-col gap-y-1 mt-5 pl-5">
              <div className="text-sm flex items-center gap-x-1">
                <TiTick size={18} className="text-main" />
                <span>Holder Verification</span>
              </div>
              <div className="text-sm flex items-center gap-x-1">
                <TiTick size={18} className="text-main" />
                <span>Staking System</span>
              </div>
              <div className="text-sm flex items-center gap-x-1">
                <TiTick size={18} className="text-main" />
                <span>Airdrop System</span>
              </div>
              <div className="text-sm flex items-center gap-x-1">
                <TiTick size={18} className="text-main" />
                <span>Revenue Sharing System</span>
              </div>
              <div className="text-xs italic flex justify-end pr-14">
                <button
                  className="text-main cursor-pointer z-50 p-2"
                  onClick={() =>
                    window.open('https://tektools.app/#pricingTop', '_blank')
                  }
                >
                  more +
                </button>
              </div>
            </div>

            <div className="flex justify-end items-end absolute bottom-10 right-44">
              <Button
                color="deep-purple"
                onClick={() =>
                  window.open('https://discord.com/invite/tekros', '_blank')
                }
                className="bg-main text-white z-50"
              >
                Upgrade your Workspace
              </Button>
            </div>
          </div>
          <div className="w-72">
            <img
              src={TekrosSpeaker}
              alt=""
              className="w-[400px] h-[500px] absolute -right-32 bottom-0"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Premium
