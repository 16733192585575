import { Button, CardBody, CardFooter } from '@material-tailwind/react'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { InputDefault } from '../components/ui/input'
import { HttpService } from '../../utils/axios-httpService'

const CreateWallet = () => {
  const [walletName, setWalletName] = useState('')
  const [walletInfo, setWalletInfo] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    setWalletName('')
    setWalletInfo('')
  }

  const createNewWallet = () => {
    setLoading(true)
    const errors = []
    if (!walletName) {
      errors.push('Please fill in all fields')
    }
    if (!walletInfo) {
      errors.push('Please fill in all fields')
    }
    if (errors.length > 0) {
      toast.error(errors.join(' '))
      setLoading(false)
      return
    }
    const infoWallet = {
      guildId: localStorage.getItem('guildId'),
      walletName: walletName.trim(),
      info: walletInfo.trim(),
    }


    HttpService.postCreateNewWallet(infoWallet)
      .then((data) => {
        toast.success('Wallet created successfully!')
        handleCancel()
        setLoading(false)
      })
      .catch((error) => {
        toast.error(error.message)
        setLoading(false)
      })
  }

  return (
    <>
      <section className="flex justify-center items-center">
        <div className="w-full max-w-[900px] h-fullp-5 text-white">
          <CardBody className="flex flex-col gap-y-5">
            {/* wallet name */}
            <InputDefault
              placeholder="Enter the wallet name here*"
              value={walletName}
              onChange={(e) => setWalletName(e.target.value)}
              label="Enter a name for your wallet"
            />
            {/* Wallet info */}
            <InputDefault
              placeholder="Enter the wallet info here*"
              value={walletInfo}
              onChange={(e) => setWalletInfo(e.target.value)}
              label="Enter a info for your wallet"
            />
          </CardBody>
          <CardFooter className="flex justify-end py-0">
            <Button variant="text" color="red" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              variant="filled"
              color="purple"
              onClick={() => createNewWallet()}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Create'}
            </Button>
          </CardFooter>
        </div>
      </section>
    </>
  )
}

export default CreateWallet
