import { Button, Card, Input } from '@material-tailwind/react'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { BiSearch } from 'react-icons/bi'
import { CgTrash } from 'react-icons/cg'
import { fetchRoles } from '../../../utils/rolesUtils'
import { fetchTraits } from '../../../utils/traitsUtils'
import { SelectDefault } from '../../components/ui/select'

export const RolePerAttibute = ({
  RoleAttribute,
  creatorAddress,
  collectionAddress,
  collectionMint,
  listRoleAttribute,
}) => {
  const guildId = localStorage.getItem('guildId')
  const [roles, setRoles] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedRole, setSelectedRole] = useState(null)
  const [rolesPerAtt, setRolesPerAtt] = useState(
    RoleAttribute
      ? RoleAttribute.map(
        ({ RoleName, AttributeName, AttributeValue, RoleId }) => ({
          RoleName,
          AttributeName,
          AttributeValue,
          RoleId,
        })
      )
      : []
  )
  const [newTraits, setNewTraits] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedTraitType, setSelectedTraitType] = useState(null)
  const [selectedTraitValue, setSelectedTraitValue] = useState(null)
  const traitTypeRef = useRef(null)
  const traitValueRef = useRef(null)
  const roleRef = useRef(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  // Load traits

  const getTraits = () => {
    fetchTraits(collectionMint, creatorAddress, guildId).then((data) => {
      setNewTraits(data)
    })
  }

  useEffect(() => {
    if (newTraits?.length === 0 && (collectionMint || creatorAddress)) {
      getTraits()
    }
  }, [collectionMint, creatorAddress, guildId])

  const traitOptions = newTraits?.map((trait) => ({
    value: trait.TraitType,
    label: trait.TraitType,
  }))

  const traitValueOptions = selectedTraitType
    ? newTraits
      ?.find((trait) => trait.TraitType === selectedTraitType.value)
      ?.TraitValue?.filter(
        (value) =>
          !rolesPerAtt.some((roleAtt) => roleAtt.AttributeValue === value)
      )
      ?.map((value) => ({
        value,
        label: value,
      })) || []
    : []

  // filter roles per attribute
  const filteredRolesPerAtt = rolesPerAtt?.filter(
    (roleQty) =>
      roleQty.RoleName &&
      roleQty.RoleName.toLowerCase().includes(searchQuery.toLowerCase())
  )

  // add
  const handleAddRoleAttribute = () => {
    const errors = []
    if (!selectedRole) {
      errors.push('Please select a role.')
    }
    if (!selectedTraitType) {
      errors.push('Please select an attribute type.')
    }
    if (!selectedTraitValue) {
      errors.push('Please select an attribute value.')
    }
    if (errors.length > 0) {
      toast.error(errors.join(' '))
      return
    }

    const newRoleAttribute = {
      RoleId: selectedRole.value,
      RoleName: selectedRole.label,
      AttributeName: selectedTraitType.label,
      AttributeValue: selectedTraitValue.label,
    }

    const exists = rolesPerAtt.some(
      (item) =>
        item.RoleId === newRoleAttribute.RoleId &&
        item.AttributeName === newRoleAttribute.AttributeName &&
        item.AttributeValue === newRoleAttribute.AttributeValue
    )
    if (exists) {
      toast.error('This item has already been added.')
      return
    }
    const updatedRolesPerAtt = [...rolesPerAtt, newRoleAttribute]
    setRolesPerAtt(updatedRolesPerAtt)
    setSelectedRole(null)
    setSelectedTraitType(null)
    setSelectedTraitValue(null)
    traitTypeRef.current?.clearValue()
    traitValueRef.current?.clearValue()
    roleRef.current?.clearValue()
  }

  // delete
  const handleDeleteRoleQty = (index) => {
    const updatedRolesPerAtt = rolesPerAtt.filter((_, i) => i !== index)
    setRolesPerAtt(updatedRolesPerAtt)
  }

  useEffect(() => {
    listRoleAttribute(rolesPerAtt)
  }, [rolesPerAtt, listRoleAttribute])

  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex gap-3 w-full">
          {/* TYPE */}
          <SelectDefault
            isLoading={isLoading}
            options={traitOptions}
            onChange={(selectedOption) => {
              setSelectedTraitType(selectedOption)
              setSelectedTraitValue(null)
              traitValueRef.current?.clearValue()
            }}
            placeholder="Select Type"
            label="Type"
            value={selectedTraitType}
          />
          {/* VALUE */}
          <SelectDefault
            isLoading={isLoading}
            options={traitValueOptions}
            isDisabled={!selectedTraitType}
            onChange={(selectedOption) => {
              setSelectedTraitValue(selectedOption)
            }}
            placeholder="Select Value"
            label="Value"
            value={selectedTraitValue}
          />
          {/* ROLE */}
          <SelectDefault
            isLoading={isLoading}
            options={dropdownRoles}
            onChange={(selectedOption) => {
              setSelectedRole(selectedOption)
            }}
            placeholder="Select Role"
            label="Role"
            value={selectedRole}
          />
          {/* ADD BUTTON */}
          <div className="flex items-end justify-center shadow-lg">
            <Button
              color="deep-purple"
              className="bg-main hover:bg-mainHover w-12 h-full max-h-[52px] flex items-center justify-center"
              onClick={handleAddRoleAttribute}
            >
              ADD
            </Button>
          </div>
        </div>
        {/* Grid of Role Quantity */}
        <Card className="bg-basic min-h-28 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-lg">
          <Input
            placeholder="Filter by role, type or attribute"
            variant="static"
            color="deep-purple"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            className="w-full bg-second border-none px-5 text-white"
            icon={<BiSearch className="text-xl text-main -ml-10" />}
          />
          <table className="w-full text-left">
            <thead className="text-xs bg-second uppercase">
              <tr>
                <th scope="col" className="hidden text-center py-2">
                  ID
                </th>
                <th scope="col" className="text-start py-2 pl-5 ">
                  Type
                </th>
                <th scope="col" className="text-start py-2 pl-5">
                  Value
                </th>
                <th scope="col" className="text-start py-2 pl-5">
                  Role
                </th>
                <th scope="col" className="text-start py-2 pl-5"></th>
              </tr>
            </thead>
            {filteredRolesPerAtt?.map((roleQty, index) => (
              <tbody
                key={index}
                className={!filteredRolesPerAtt ? 'hidden' : ''}
              >
                <tr className="bg-basic hover:bg-third border-second border-t font-light">
                  <td className="px-5 py-2 border-r border-second">
                    {roleQty.AttributeName}
                  </td>
                  <td className="px-5 py-2 border-r border-second">
                    {roleQty.AttributeValue}
                  </td>
                  <td className="px-5 py-2 border-r border-second">
                    {roleQty.RoleName}
                  </td>
                  <td className="hidden px-3 p-2 border-r border-second">
                    {roleQty.RoleId}
                  </td>
                  <td className="w-10 h-8 hover:bg-accentHover">
                    <Button
                      color="deep-purple"
                      className="bg-main hover:bg-mainHover flex items-center justify-center text-white h-full w-10 p-px rounded-none rounded-l-lg"
                      onClick={() => handleDeleteRoleQty(index)}
                    >
                      <CgTrash size={20} />
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
          {!filteredRolesPerAtt.length && (
            <div className="text-center italic text-xs p-2">
              no rule has been configured
            </div>
          )}
        </Card>
      </div>
    </>
  )
}
