import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Spinner,
  Switch,
} from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { BiChevronDown, BiTrash } from 'react-icons/bi'
import { BiEdit, BiSearchAlt2 } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchCollections } from '../../utils/collectionUtils'
import SendInstructions from './_components/SendInstructions'

const LoadHolderVerification = ({ updateTrigger }) => {
  const guildId = localStorage.getItem('guildId')
  const [openSendInstructions, setOpenSendInstructions] = useState(false)
  const [listCollections, setListCollections] = useState([])
  const [serverList, setServerList] = useState([])
  const [search, setSearch] = useState('')
  const [openOptionsCard, setOpenOptionsCard] = useState(null)
  const [selectedCollection, setSelectedCollection] = useState(null)

  const handleSendInstructions = () => {
    setOpenSendInstructions((current) => !current)
  }

  const handleSendInstructionsClose = () => {
    setOpenSendInstructions(false)
  }

  const [openDialog, setOpenDialog] = useState(false)
  const handleOpenDialog = (collection) => {
    setSelectedCollection(collection)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedCollection(null)
  }

  const updateLoadCollection = () => {
    fetchCollections(guildId).then((data) => {
      setListCollections(data)
    })
  }

  useEffect(() => {
    if (!listCollections || listCollections.length === 0) {
      updateLoadCollection()
    }
  }, [])

  const disableCollectionById = async () => {
    if (!selectedCollection) return
    const infoDisable = {
      guildId: localStorage.getItem('guildId'),
      collectionId: selectedCollection._id,
      disable: true,
    }
    HttpService.disableCollectionById(infoDisable)
      .then(() => {
        toast.success('Collection disabled successfully!')
        updateLoadCollection()
        handleCloseDialog()
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
      })
  }

  // Short Address
  const shortAddress = (address) => {
    if (!address) return ''
    return `${address.substring(0, 3)}...${address.substring(address.length - 3)}`
  }

  return (
    <>
      <section className="container mx-auto flex flex-col gap-y-5">
        <div className="relative w-full">
          {/* Filter  */}
          <div className="flex flex-row justify-between ">
            <div className="w-full max-w-96 ">
              <Input
                color="purple"
                placeholder="Search by name or address"
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className="w-full text-white "
                icon={<BiSearchAlt2 className="text-white active:text-main " />}
                variant="static"
                containerProps={{
                  className: 'text-white',
                }}
              />
            </div>
            {/* Acction Button */}
            <div className="flex flex-row gap-x-px bg-accentMain hover:bg-accentHouver rounded-lg transition-all duration-700">
              <Button
                color="deep-purple"
                className="flex flex-row items-center justify-center text-center gap-x-2 w-32 h-12 text-xs font-bold -tracking-wide p-2  bg-accentMain transition-all duration-300 hover:bg-accentHouver rounded-rl "
                onClick={() => handleSendInstructions()}
              >
                Send instructions
              </Button>
              <div className="h-full w-px bg-third"></div>
              <Button
                color="deep-purple"
                className={`w-7 flex items-center justify-center transition-transform  bg-accentMain hover:bg-accentHouver px-1 rounded-l ${openOptionsCard ? 'rotate-180 ' : ''}`}
                // onClick={() => handleOptions()}
              >
                <BiChevronDown strokeWidth={1.5} />
              </Button>
            </div>
          </div>
          {/* Send Instructions */}
          {openSendInstructions && (
            <div
              className={`absolute bottom-0 right-40 mt-2 w-96 rounded-lg z-50 bg-third p-2 shadow-xl shadow-main/25 transform ease-in-out transition-all duration-700 opacity-100 scale-100`}
            >
              <SendInstructions onClose={handleSendInstructionsClose} />
            </div>
          )}
        </div>
        <div className="flex flex-wrap gap-5 overflow-y-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic pb-10 items-center justify-center">
          {/* Skeleton */}
          {!listCollections ? (
            <Card className="h-56 w-80 group overflow-hidden transition-opacity hover:opacity-90 bg-second">
              <div className="absolute w-full h-full flex items-center justify-center">
                <Spinner className="w-12 h-12 text-main/10" />
              </div>
              <div className="w-full h-full flex flex-row bg-basic rounded-lg">
                <div className="w-4/5 h-full flex flex-col bg-second shadow-md rounded-lg gap-x-2 transition-all duration-300"></div>
              </div>
            </Card>
          ) : (
            <>
              {Array.isArray(listCollections) &&
                listCollections.length > 0 &&
                listCollections
                  ?.flatMap((holder) =>
                    holder?.CollectionNotifications.filter(
                      (collection) =>
                        (collection?.DisplayName?.toLowerCase().includes(
                          search?.toLowerCase()
                        ) ||
                          collection?.VerifiedAddress?.toLowerCase().includes(
                            search?.toLowerCase()
                          )) &&
                        collection.IsEnabled
                    )
                      .slice()
                      .reverse()
                      .map((collection, collectionIndex) => ({
                        holder,
                        collection,
                        collectionIndex,
                      }))
                  )
                  .map(
                    ({ holder, collection, collectionIndex }, holderIndex) => (
                      <Card
                        className="h-56 w-80 group overflow-hidden transition-opacity hover:opacity-90 bg-second"
                        key={`${holderIndex}-${collectionIndex}`}
                      >
                        {/* Card loaded with staking list */}
                        <div className="w-full h-full flex flex-row bg-basic rounded-lg">
                          {/* Esquerda */}
                          <div className="w-4/5 h-full flex flex-col bg-second shadow-md rounded-lg gap-x-2 transition-all duration-300">
                            {/* Collection name */}
                            <div className="relative w-full flex flex-row items-end pt-5 pl-5">
                              <div className="flex flex-row  justify-between text-xl font-bold border-b-2 border-basic transition-all w-full pl-1">
                                {collection.DisplayName ||
                                  collection.CollectionDisplayName ||
                                  collection.CollectionName}
                                <Chip
                                  value={collection.IsToken ? 'Token' : 'NFT'}
                                  className={`text-xs p-1 mr-2 mb-1 ${collection.IsToken ? 'bg-main' : 'bg-accentMain'}`}
                                />
                              </div>
                            </div>
                            <div className="w-full h-full flex flex-col items-end justify-start gap-y-5">
                              <div className="w-20 h-2 flex text-center items-center justify-center bg-basic rounded-bl-lg -mr-1 group-hover:w-44 transition-all duration-300"></div>
                              <div className="w-full h-full flex flex-row">
                                <div className="flex flex-col w-full items-start gap-y-5 pl-5">
                                  {/* Creator Address id */}
                                  <div className="flex  w-full gap-x-2">
                                    {collection.IsToken === true ? (
                                      <h3 className="text-base">
                                        Token Address:{' '}
                                      </h3>
                                    ) : (
                                      <h3 className="text-base">
                                        {collection.CollectionMint
                                          ? 'Collection Mint'
                                          : 'Creator Address'}
                                      </h3>
                                    )}
                                    {collection.IsToken === false ? (
                                      <h4 className="text-base truncate pr-2 transition-all duration-300">
                                        {collection.CollectionMint
                                          ? shortAddress(
                                              collection.CollectionMint
                                            )
                                          : shortAddress(
                                              collection.VerifiedAddress
                                            )}
                                      </h4>
                                    ) : (
                                      <h4 className="text-base pr-2 transition-all duration-300">
                                        {shortAddress(
                                          collection.TokenAddress ||
                                            collection.VerifiedAddress
                                        )}
                                      </h4>
                                    )}
                                  </div>

                                  {/*  Holder Role */}
                                  <div className="flex-row w-full items-center hidden">
                                    <span className="text-sm flex gap-x-2">
                                      Holder role{' '}
                                      {Array.isArray(serverList)
                                        ? serverList.find(
                                            (server) =>
                                              server.id ===
                                              collection.HolderRole
                                          )?.name
                                        : null}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row w-full gap-x-2 items-start pl-5 pb-5">
                              <NavLink
                                className="flex flex-row items-center justify-center text-center gap-x-2 w-24 h-10 text-base font-bold px-2  bg-main transition-all duration-300  rounded-lg shadow-md hover:shadow-main hover:shadow-sm"
                                to={`/dashboard/holderverification/${collection._id}`}
                              >
                                <BiEdit className="text-lg" />
                                Edit
                              </NavLink>
                            </div>
                          </div>

                          {/* ______________________________________________________________________________________________ */}

                          {/* Direita */}
                          <div className="w-1/5 h-full flex flex-col items-start pl-3 justify-center gap-y-5 transition-all duration-300 relative">
                            <div className="absolute top-2 right-4">
                              <Button
                                color="deep-purple"
                                variant="text"
                                className="text-sm font-bold p-2  bg-transparent transition-all duration-300  border-none active:border-none text-main hover:text-red-400"
                                onClick={() => handleOpenDialog(collection)}
                              >
                                <BiTrash className="text-lg" />
                              </Button>
                            </div>
                            {collection.IsToken === false && (
                              <div className="flex flex-col items-center justify-center gap-y-1">
                                <span className="text-sm">Sales</span>
                                <Switch
                                  className={`${collection.Sales?.IsEnabled ? 'bg-main' : 'bg-third'}`}
                                  checked={collection.Sales?.IsEnabled || false}
                                  onChange={() => {}}
                                />
                              </div>
                            )}
                            {collection.IsToken === false && (
                              <div className="flex flex-col items-center justify-center gap-y-1">
                                <span className="text-sm">Listing</span>
                                {collection.Listing ? (
                                  <Switch
                                    className={`${collection.Listing?.IsEnabled ? 'bg-main' : 'bg-third'}`}
                                    checked={
                                      collection.Listing?.IsEnabled || false
                                    }
                                    onChange={() => {}}
                                  />
                                ) : (
                                  <Switch
                                    className={`${collection.Listing ? 'bg-main' : 'bg-third'}`}
                                    checked={false}
                                    onChange={() => {}}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </Card>
                    )
                  )}
            </>
          )}
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            className="text-white bg-bgAdmin"
            size="xs"
          >
            <DialogHeader className="text-white uppercase flex flex-col items-start">
              <div className="pl-px">Delete Collection</div>
              <hr className="w-full border-basic" />
            </DialogHeader>
            <DialogBody className="text-white">
              Are you sure you want to delete this collection{' '}
              {selectedCollection?.DisplayName}?
            </DialogBody>
            <DialogFooter className="flex flex-row gap-x-2">
              <Button onClick={handleCloseDialog} variant="text" color="red">
                Cancel
              </Button>
              <Button onClick={disableCollectionById} color="purple" autoFocus>
                Confirm
              </Button>
            </DialogFooter>
          </Dialog>
        </div>
      </section>
    </>
  )
}

export default LoadHolderVerification
