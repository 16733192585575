import { Button, Input, Option, Select } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ListTraitsReveneuSharing from './ListTraitsReveneuSharing'
import { SelectDefault } from '../../components/ui/select'
import { InputNumber } from '../../components/ui/input'
//TODO: Montar o componente de adicionar trait

const AddTrait = () => {
  const [traitRewards, setTraitRewards] = useState([])

  return (
    <>
      <section>
        <div className="flex justify-end items-end gap-x-5">
          <SelectDefault />
          <SelectDefault />
          <InputNumber />
          {/* ADD BUTTON */}
          <div className="flex items-end justify-center shadow-lg h-full max-h-[52px]">
            <Button
              color="deep-purple"
              className="bg-main hover:bg-mainHover w-12 h-full max-h-[52px] flex items-center justify-center"
              onClick={() => console.log('add')}
            >
              ADD
            </Button>
          </div>
        </div>
      </section>
      {traitRewards.length > 0 && (
        <ListTraitsReveneuSharing listTraits={traitRewards} />
      )}
    </>
  )
}

export default AddTrait
