import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

// Create a base axios instance with default configurations
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000, // 30 seconds timeout
  headers: {
    'Content-Type': 'application/json',
  },
})

export const setupAxiosInterceptors = () => {
  const navigate = useNavigate()

  // Utility to exclude specific endpoints from token injection
  const noAuthUrls = ['/auth']

  // Add request interceptor to attach JWT
  api.interceptors.request.use(
    (config) => {
      const isPublicEndpoint = noAuthUrls.some((url) =>
        config.url.includes(url)
      )
      if (isPublicEndpoint) {
        return config
      }
      const userData = JSON.parse(localStorage.getItem('userData'))
      const accessToken = userData.access_token
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  const ShowExpiredSessionMessage = (hasTokenExpired) => {
    const errorMessage = hasTokenExpired
      ? 'Your session has expired. Please log in again.'
      : "You're not logging into the application."

    // Show session expiration message
    toast.error(`${errorMessage} We are redirecting you to the home page.`, {
      toastId: 'unique-error-toast',
      position: 'top-right',
      autoClose: 3000,
      onClose: () => {
        // Clears local storage
        localStorage.clear()

        // Redirect to home page
        navigate('/')
      },
    })
  }

  // Response interceptor for handling token expiration
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      // Check if the error is due to token expiration
      if (error.response && error.response.status === 401) {
        const hasSessionExpired =
          error.response.data.message.message === 'Token expired'
        ShowExpiredSessionMessage(hasSessionExpired)

        return Promise.reject(error)
      }
      return Promise.reject(error)
    }
  )
}

// Example service methods
export const HttpService = {
  getGuilds: () => api.get(`/guilds`),
  getChannelsByGuildId: (guildId) => api.get(`/channels/${guildId}`),
  getRolesByGuildId: (guildId) => api.get(`/roles/${guildId}`),
  postUserVerification: (request) => api.post(`/users/verification`, request),
  postAuth: (request) => api.post(`/auth`, request),
  // Collections
  getCollections: (guildId) => api.get(`/collections?guildId=${guildId}`),
  getCollectionById: (collectionId, guildId) => api.get(`/collections/${collectionId}?guildId=${guildId}`),
  disableCollectionById: (request) => api.patch(`/collections/disable-by-id`, request),
  postCollectionDefinition: (request) => api.post(`/collections/definition`, request),
  postCollectionRoles: (request) => api.post(`/collections/roles`, request),
  postTraits: (request) => api.post(`/collections/traits`, request),
  patchCollectionNotificationInfo: (guildId, collectionId, request) => api.patch(`/collections/notification-info?guildId=${guildId}&collectionId=${collectionId}`, request),
  // Staking
  getStakingByGuildId: (guildId) => api.get(`/staking?guildId=${guildId}`),
  postCreateStaking: (request) => api.post(`/staking`, request),
  // Token
  getTokenByGuildId: (guildId) => api.get(`/token/${guildId}`),
  postCreateToken: (request) => api.post(`/token`, request),
  // Revenue Sharing
  getRevSharingByGuildId: (guildId) => api.get(`/revenue-sharing/list?guildId=${guildId}`),
  postCreateRevSharing: (request) => api.post(`/revenue-sharing`, request),
  // Storage
  getStorageByGuildId: (guildId) => api.get(`/storage?guildId=${guildId}`),
  // Polls
  getPollsByGuildId: (guildId) => api.get(`/polls?guildId=${guildId}`),
  // Wallets
  getWalletsByGuildId: (guildId) => api.get(`/wallets?guildId=${guildId}`),
  postCreateNewWallet: (request) => api.post(`/wallets`, request),
  deleteWalletById: (guildId, walletId) => api.delete(`/wallets/${walletId}?guildId=${guildId}`),
  // Ticket 
  postCreateTicketAssistant: (request) => api.post(`/tickets`, request),
}

